import React, { useEffect, useState } from 'react';

import { INote } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';

import {
  AboutSection,
  AutoSaveInfoBar,
  NoteDashboardContainer,
  NotesEditorHeader,
  NotesEditorHeaderContainer,
  NotesEditorWrapper,
  StyledEditor,
  StyledInput,
  Title,
} from './design';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import useBoolState from '~/hooks/useBoolState';

import { UserAvatarsGroup } from '../components/UserAvatarGroup';

interface INoteDashboardProps {
  note: INote;
  onUpdate: (params: Partial<INote>) => void;
  autoSaveState: useAutoSaveFunc;
  onDelete: (noteId: INote['id']) => Promise<void>;
}

export const NoteDashboard = ({ note, onUpdate, autoSaveState, onDelete }: INoteDashboardProps) => {
  const { i18n } = useLingui();

  const $editTitle = useBoolState();

  const [title, setTitle] = useState(note?.name);

  useEffect(() => {
    setTitle(note?.name);
  }, [note]);

  const onChange = (params: Partial<INote>) => {
    onUpdate(params);
  };

  const handleTitleBlur = (value: string) => {
    $editTitle.off();
    setTitle(value);
    onChange({ name: title });
  };

  return (
    <NoteDashboardContainer>
      <NotesEditorHeaderContainer>
        <NotesEditorHeader>
          {$editTitle.value ? (
            <StyledInput
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(event.target.value)
              }
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleTitleBlur(event.target.value)
              }
            />
          ) : (
            <Title onClick={$editTitle.on}>{title}</Title>
          )}
          <ContextMenu className="menu" menuHeight={100}>
            <ContextOption
              action={() => onDelete(note.id)}
              key="delete"
              icon={ICONS.DELETE_BIN}
              isWarning
            >
              <Trans>Delete</Trans>
            </ContextOption>
          </ContextMenu>
        </NotesEditorHeader>
        <AboutSection>
          <Trans>About:</Trans>
          {note && !isEmpty(note?.createdFor) && (
            <UserAvatarsGroup createdFor={note?.createdFor} size={24} fontSize={14} />
          )}
        </AboutSection>
      </NotesEditorHeaderContainer>
      <NotesEditorWrapper>
        <StyledEditor
          placeholder={i18n._(t`Write your note`)}
          onChange={onChange}
          value={note?.value}
          hideToolbarOnStart={false}
          compact
          minHeight="calc(100vh - 215px)"
        />
        <AutoSaveInfoBar>
          <LastSaved
            time={autoSaveState.state.time}
            status={autoSaveState.state.status}
            errorMessage={autoSaveState.state.message}
          />
        </AutoSaveInfoBar>
      </NotesEditorWrapper>
    </NoteDashboardContainer>
  );
};
