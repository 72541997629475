import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getNote(noteId) {
  return cloudRequest(serverRoutes.notes.getNote(noteId));
}

export function getNotes({ search, employees }) {
  const filters = { search, employees };

  return cloudRequest(serverRoutes.notes.getNotes, {}, { filters });
}

export function createNote({ name, createdFor }) {
  return cloudRequest(serverRoutes.notes.createNote, {}, { name, createdFor });
}

export function updateNote(noteId, body) {
  return cloudRequest(serverRoutes.notes.updateNote(noteId), {}, body);
}

export function removeNote(noteId) {
  return cloudRequest(serverRoutes.notes.removeNote(noteId));
}

export function getEmployeesFromNotes() {
  return cloudRequest(serverRoutes.notes.fetchEmployeesFromUserNotes);
}
