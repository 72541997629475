import styled from 'styled-components';

import Editor from '~/components/Editor';
import { Input } from '~/components/Input';

import { COLORS } from '~/styles';

export const NoteDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  width: calc(100% - 370px);
`;

export const NotesEditorHeaderContainer = styled.div`
  height: 117px;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 24px 23px 16px;
`;

export const NotesEditorHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 24px;
`;

export const Title = styled.div`
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 10px;
  width: 100%;

  overflow: hidden;
  color: ${COLORS.TEXT_HOVER};
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    border-radius: 6px;
    border: 1px solid ${COLORS.COMPANY};
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 5px;
  width: 100%;

  input {
    border-radius: 6px;
    border: 1px solid ${COLORS.COMPANY};
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
    padding: 0;
    margin: 0;
    max-height: 24px;
    font-size: 16px;
  }
`;

export const AboutSection = styled.div`
  display: flex;
  height: 30px;
  padding: 2px 8px;
  align-items: center;
  gap: 16px;

  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const NotesEditorWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledEditor = styled(Editor)`
  box-sizing: border-box;
  height: calc(100vh - 150px);

  .ql-toolbar {
    padding-left: 54px;
    border-radius: unset;
    border-bottom: 1px solid ${COLORS.BORDERS};
  }

  .ql-container {
    margin-top: 0;
    margin: 24px 54px;
    border: none !important;
  }

  .ql-editor.ql-blank::before {
    color: ${COLORS.EDITOR_PLACEHOLDER}
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; 
  }

  .ql-editor {
    padding: 0;
    color: ${COLORS.ICONS_PRIMARY};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; 
  }
`;

export const AutoSaveInfoBar = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 34px;
  margin-left: 54px;
`;
