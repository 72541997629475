import styled from 'styled-components';

import { SearchSelect } from '~/components/SearchSelect';

import { COLORS } from '~/styles';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: ${COLORS.TEXT_HOVER};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 16px 0 24px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 436px;

  .content {
    max-width: 200px;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  height: 20px;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Label = styled.p`
  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
`;

export const StyledSearchSelect = styled(SearchSelect)`
  .options-list {
    left: 0;
    width: 100%;
  }

  .content {
    max-width: fit-content;
    overflow-x: hidden;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButtonContainer = styled.div`
  padding-top: 24px;
  display: flex;
  gap: 8px;
`;
