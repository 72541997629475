import React, { ChangeEvent, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';

import { CreateNoteModal } from './components/CreateNoteModal';
import { NoteSummary } from './components/NoteSummary';
import {
  NotebookContainer,
  NotesListContainer,
  NotesListHeader,
  NotesListHeaderContainer,
  NotesListSection,
  NotesListTitle,
} from './design';
import { useNote } from './hooks/useNote';
import { SearchContainer, SearchIcon, SearchPlaceholder } from './NotebookSideMenu/design';
import { NoteDashboard } from './NoteDashboard';

export const Notebook = () => {
  const { i18n } = useLingui();

  const [value, setValue] = useState('');

  const {
    notes,
    selectedEmployee,
    onNoteSelect,
    selectedNote,
    isLoading: $isLoading,
    autoSaveState,
    onUpdate,
    onDelete,
    openDeleteWarningModal,
    confirmNoteDelete,
    setNoteToBeDeleted,
    onCreateNote,
    openCreateNoteModal,
  } = useNote();

  return (
    <NotebookContainer>
      <NotesListSection>
        <NotesListHeaderContainer>
          <NotesListHeader>
            <NotesListTitle>
              <Trans>All notes</Trans>
            </NotesListTitle>
            <Button
              label={i18n._(t`Create note`)}
              variant={ButtonVariant.PRIMARY}
              onClick={openCreateNoteModal.on}
            />
          </NotesListHeader>
          <SearchContainer>
            <SearchPlaceholder
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValue(e.currentTarget.value);
              }}
              value={value}
              placeholder={i18n._(t`Search note...`)}
            />
            <SearchIcon icon={ICONS.SEARCH} size={ICON_SIZES.MEDIUM} />
          </SearchContainer>
        </NotesListHeaderContainer>
        <NotesListContainer>
          {notes.map((note) => (
            <NoteSummary
              key={note.id}
              note={note}
              isSelected={note.id === selectedNote?.id}
              onClick={() => onNoteSelect(selectedEmployee, note.id)}
              onDelete={onDelete}
            />
          ))}
        </NotesListContainer>
      </NotesListSection>
      {selectedNote && (
        <NoteDashboard
          autoSaveState={autoSaveState}
          note={selectedNote}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
      {openCreateNoteModal.value && (
        <CreateNoteModal
          onClose={openCreateNoteModal.off}
          isLoading={$isLoading.value}
          createdFor={selectedEmployee}
          onCreateNote={onCreateNote}
        />
      )}
      {openDeleteWarningModal.value && (
        <ConfirmationModal
          title={i18n._(t`Delete note?`)}
          description={i18n._(
            t`Are you sure you want to delete this note? This action cannot be undone.`,
          )}
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          onSubmit={confirmNoteDelete}
          onClose={() => {
            openDeleteWarningModal.off();
            setNoteToBeDeleted(undefined);
          }}
        />
      )}
    </NotebookContainer>
  );
};
