import React from 'react';

import { UserAvatar } from '~/components/UserAvatar';

import { FolderContainer } from './design';

interface INotebookFolderProps {
  textTitle?: string;
  userId?: string;
  isSelected?: boolean;
  onClick: () => void;
  employeeSearch?: string;
}

export const NotebookFolder = ({
  textTitle,
  userId,
  isSelected,
  onClick,
  employeeSearch,
}: INotebookFolderProps) => {
  return (
    <FolderContainer isTextTitle={!!textTitle} isSelected={isSelected} onClick={onClick}>
      {textTitle && <span className="title">{textTitle}</span>}
      {userId && <UserAvatar userId={userId} options={{ highlight: employeeSearch }} />}
    </FolderContainer>
  );
};
