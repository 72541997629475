import React from 'react';

import styled, { css } from 'styled-components';

import { UserAvatar } from '~/components/UserAvatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import { COLORS } from '~/styles';

interface IUserAvatarGroup {
  createdFor: string[];
  size?: number;
  fontSize?: number;
}

const UserAvatarGroupWrapper = styled.div<{ size?: number; fontSize?: number }>`
  display: flex;
  gap: 10px;

  .avatar {
    ${({ size }) =>
      size
        ? css`
            height: ${size};
            width: ${size};
          `
        : css`
            height: 16px;
            width: 16px;
          `}

    min-height: unset;
    min-width: unset;
  }

  .name-label {
    max-width: 170px;
    overflow: hidden;
    color: ${COLORS.TEXT_MAIN};
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .username {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : 12)}px;
  }
`;

export const UserAvatarsGroup = ({ createdFor, size, fontSize }: IUserAvatarGroup) => {
  return (
    <UserAvatarGroupWrapper size={size} fontSize={fontSize}>
      {createdFor?.length === 1 ? (
        <UserAvatar userId={createdFor[0]} />
      ) : (
        <UserAvatarGroup users={createdFor.map((userId) => ({ userId }))} />
      )}
    </UserAvatarGroupWrapper>
  );
};
