import React from 'react';

import { COLORS } from '~/styles';

import { StyledPlaceholder } from '../design';

interface INotebookPlaceholderProps {
  title: string;
  subTitle: string;
}

export const NotebookPlaceholder = ({ title, subTitle }: INotebookPlaceholderProps) => {
  return (
    // @ts-ignore
    <StyledPlaceholder
      title={title}
      subTitle={subTitle}
      titleStyles={{ color: COLORS.SUBTEXT }}
      subTitleStyles={{ fontSize: 12 }}
    />
  );
};
