import styled, { css } from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

export const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background: ${COLORS.NOTE_SELECTED};
        `
      : ''};

  &:hover {
    ${({ isSelected }) =>
      isSelected
        ? css`
            background: ${COLORS.NOTE_SELECTED};
          `
        : css`
            background: ${COLORS.HOVER};
          `};

    .title {
      overflow: visible;
      color: ${COLORS.TEXT_HOVER};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .menu {
      visibility: visible;
    }
  }

  .menu {
    visibility: hidden;
  }
`;

export const TitleWrapper = styled.div`
  overflow: visible;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
`;

export const Content = styled(RickTextView)`
  color: ${COLORS.TEXT_MAIN};
  line-height: 18px;
  font-size: 12px;
  text-overflow: ellipsis;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  max-height: 3em;
  height: 36px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  height: 18px;
  align-items: center;
  align-self: stretch;

  .note-date {
    overflow: hidden;
    color: ${COLORS.LABEL_GRAY};
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`;
