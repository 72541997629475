import React from 'react';

import { IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { NotebookFolderList } from './NotebookFolderList';
import { NotebookPlaceholder } from './NotebookPlaceholder';

import { NotebookFolder } from '../../components/NotebookFolder';
import { IEmployeeFolder } from '../../types';
import { NotebookFoldersContainer, PlaceholderWrapper } from '../design';

interface INoteBookMenuSectionsProps {
  employeeSearch: string;
  currentUser: IUser;
  selectedEmployeeId: string;
  filteredEmployees: IEmployeeFolder[];
  employees: IEmployeeFolder[];
  handleFolderSelect: (userId?: string) => void;
}

export const NoteBookMenuSections = ({
  employeeSearch,
  currentUser,
  selectedEmployeeId,
  filteredEmployees,
  employees,
  handleFolderSelect,
}: INoteBookMenuSectionsProps) => {
  const { i18n } = useLingui();

  if (Boolean(employeeSearch) && isEmpty(filteredEmployees)) {
    return (
      <PlaceholderWrapper>
        <NotebookPlaceholder
          title={i18n._(t`No results`)}
          subTitle={i18n._(t`No results found based on your search...`)}
        />
      </PlaceholderWrapper>
    );
  }

  return (
    <NotebookFoldersContainer>
      {!employeeSearch && (
        <>
          <NotebookFolder
            textTitle={i18n._(t`All notes`)}
            isSelected={!selectedEmployeeId}
            onClick={() => handleFolderSelect(undefined)}
          />
          <NotebookFolder
            userId={currentUser.id}
            isSelected={selectedEmployeeId === currentUser.id}
            onClick={() => handleFolderSelect(currentUser.id)}
          />
        </>
      )}

      {!isEmpty(filteredEmployees) && (
        <NotebookFolderList
          employees={filteredEmployees}
          selectedEmployeeId={selectedEmployeeId}
          handleFolderSelect={handleFolderSelect}
          employeeSearch={employeeSearch}
        />
      )}

      {isEmpty(employees) && !employeeSearch && (
        <NotebookFolderList
          employees={[]}
          selectedEmployeeId={selectedEmployeeId}
          handleFolderSelect={handleFolderSelect}
        />
      )}
    </NotebookFoldersContainer>
  );
};
