import React, { useEffect, useMemo, useState } from 'react';

import { IUser } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import Divider from '~/components/UI/Divider';

import {
  Header,
  Title,
  Content,
  Label,
  DropdownContainer,
  Footer,
  ActionButtonContainer,
  LabelContainer,
  StyledSearchSelect,
} from './design';

import { getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

interface ICreateNoteModal {
  onClose: () => void;
  isLoading: boolean;
  createdFor?: string | null;
  onCreateNote: (createdFor: IUser['id'][]) => void;
}

export const CreateNoteModal = ({
  onClose,
  isLoading,
  createdFor,
  onCreateNote,
}: ICreateNoteModal) => {
  const { i18n } = useLingui();

  const companyUsers = useSelector(getUsers);

  const allUsers: IUser[] = useMemo(() => Object.values(companyUsers), [companyUsers]);

  const [search, setSearch] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<IUser[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<IUser[]>(Object.values(companyUsers));

  useEffect(() => {
    if (createdFor) {
      const preSelectedUser = allUsers.find(({ id }) => id === createdFor);

      if (preSelectedUser) {
        setSelectedEmployees([preSelectedUser]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const regex = new RegExp(search.replaceAll('\\', ''), 'ig');
    const filtered: IUser[] = allUsers.filter((user) => getUserFullName(user).match(regex));

    setFilteredEmployees(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // Will be implemented in a future MR
  const handleSubmit = () => {
    onCreateNote(selectedEmployees.map(({ id }) => id));
  };

  const onEmployeesChange = (selected: unknown[]) => {
    setSelectedEmployees(selected as IUser[]);
  };

  const stringifyItem = (user: unknown) => {
    return getUserFullName(user as IUser);
  };

  return (
    <Modal
      width={500}
      contentStyles={{
        padding: '24px 32px',
        borderRadius: '10px',
        overflow: 'visible',
      }}
      hideFooter
      isHideHeader
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>Create note</Trans>
        </Title>
        <Button
          type="button"
          variant={ButtonVariant.ICON}
          size={ButtonSize.BIG}
          icon={ICONS.CLOSE}
          iconSize={ICON_SIZES.LARGE}
          onClick={onClose}
        />
      </Header>
      <Content>
        <DropdownContainer>
          <LabelContainer>
            <Label>
              <Trans>Who is this about?</Trans>
            </Label>
            <Label>
              (<Trans>optional</Trans>)
            </Label>
          </LabelContainer>
          <StyledSearchSelect
            selectedItems={selectedEmployees}
            onChange={onEmployeesChange}
            onSearchChange={(search: string) => setSearch(search)}
            items={filteredEmployees}
            stringifyItem={stringifyItem}
            placeholder={i18n._(t`Search employees`)}
            isExpandable
            showAvatar
            noResultsPlaceholder={i18n._(t`No employees found`)}
          />
        </DropdownContainer>
        <LabelContainer>
          <Label>
            <Icon icon={ICONS.HIDE} />
          </Label>
          <Label>
            <Trans>This note is private.</Trans>
          </Label>
        </LabelContainer>
      </Content>
      <Divider />
      <Footer>
        <ActionButtonContainer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Create note`)}
            type="button"
            isLoading={isLoading}
            disabled={isLoading}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={handleSubmit}
          />
        </ActionButtonContainer>
      </Footer>
    </Modal>
  );
};
