import React, { ChangeEvent } from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ICON_SIZES, ICONS } from '~/components/Icon';

import { NoteBookMenuSections } from './components/NoteBookMenuSections';
import {
  NotebookHeaderContainer,
  NotebookHeaderTitle,
  NotebookMenuContainer,
  SearchContainer,
  SearchIcon,
  SearchPlaceholder,
} from './design';

import routes from '~/constants/routes';
import { useQueryURL } from '~/hooks/useQueryURL';
import { getUser } from '~/selectors/baseGetters';

import { useNote } from '../hooks/useNote';

export const NotebookMenu = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const params = useParams();
  const companyId = get(params, 'companyId');

  const { values: queryParams } = useQueryURL({
    keys: ['memberId'],
  });

  const memberId = queryParams.memberId;

  const currentUser = useSelector(getUser);

  const { employees, filteredEmployees, employeeSearch, setEmployeeSearch } = useNote();

  const handleFolderSelect = (userId?: string) => {
    history.push(
      routes.NOTEBOOK_OVERVIEW.build(
        { role: ROLES.USER, companyId },
        {
          query: { ...(userId && { memberId: userId }) },
        },
      ),
    );
  };

  return (
    <NotebookMenuContainer>
      <NotebookHeaderContainer>
        <NotebookHeaderTitle>
          <Trans>Folders</Trans>
        </NotebookHeaderTitle>
        <SearchContainer>
          <SearchPlaceholder
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmployeeSearch(e.currentTarget.value);
            }}
            value={employeeSearch}
            placeholder={i18n._(t`Search employee...`)}
          />
          <SearchIcon icon={ICONS.SEARCH} size={ICON_SIZES.MEDIUM} />
        </SearchContainer>
      </NotebookHeaderContainer>
      <NoteBookMenuSections
        employeeSearch={employeeSearch}
        currentUser={currentUser}
        selectedEmployeeId={memberId}
        filteredEmployees={filteredEmployees}
        employees={employees}
        handleFolderSelect={handleFolderSelect}
      />
    </NotebookMenuContainer>
  );
};
