import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import { Notebook } from '~/pages/Notebook';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('notebook', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.NOTEBOOK_OVERVIEW.routePath(ROLES.USER)}
        exact
        path={routes.NOTEBOOK_OVERVIEW.routePath(ROLES.USER)}
        component={Notebook}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
