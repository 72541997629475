import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const FolderContainer = styled.div<{ isTextTitle: boolean; isSelected?: boolean }>`
  width: 220px;
  height: 44px;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: ${({ isTextTitle }) => (isTextTitle ? css`12px 8px 12px 12px` : '8px')};
  background: ${({ isSelected }) => (isSelected ? COLORS.COMPANY : COLORS.BG_PAGE)};

  .title {
    color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.TEXT_MAIN)};
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      .username {
        color: ${COLORS.WHITE};
      }
    `}

  &:hover {
    ${({ isTextTitle }) =>
      isTextTitle
        ? css`
            background: ${COLORS.WHITE};

            .title {
              font-size: 14px;
              color: ${COLORS.TEXT_HOVER};
              font-weight: 400;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `
        : css`
            background: ${COLORS.HOVER};

            span {
              overflow: hidden;
              color: ${COLORS.COMPANY};
              text-overflow: ellipsis;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          `};
  }
`;
