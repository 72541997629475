import { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { get } from 'lodash';
import { useHistory, useParams } from 'react-router';

import routes from '~/constants/routes';
import { useAutoSave } from '~/hooks/useAutoSave';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useQueryURL } from '~/hooks/useQueryURL';
import { removeNote, createNote, getEmployeesFromNotes } from '~/services/notes';
import getUserFullName from '~/utils/getUserFullName';

import { IEmployeeFolder } from '../types';

import type { INote, IUser } from '@learned/types';

const useNote = () => {
  const history = useHistory();
  const [notes, setNotes] = useState<INote[]>([]);
  const [selectedNote, setSelectedNote] = useState<INote>();
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<string>();
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [employees, setEmployees] = useState<IEmployeeFolder[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<IEmployeeFolder[]>([]);
  const debouncedEmployeeSearch = useDebounce(employeeSearch, 500);

  const isFilterVisible = useBoolState(false);
  const $isLoading = useBoolState();
  const $openDeleteWarningModal = useBoolState();
  const $openCreateNoteModal = useBoolState();

  const params = useParams();
  const companyId = get(params, 'companyId');

  const { values: queryParams } = useQueryURL({
    keys: ['memberId', 'noteId'],
  });

  const { memberId, noteId } = queryParams;

  const autoSaveState = useAutoSave(async () => {});

  const fetchEmployeeFolders = async () => {
    const { data } = await getEmployeesFromNotes();

    const employeesFromNotes = data?.users || [];

    setEmployees(employeesFromNotes);
    setFilteredEmployees(employeesFromNotes);
  };

  const fetchNotes = async () => {
    setNotes([]);
    onNoteSelect(memberId, noteId ? noteId : notes[0]?.id);
  };

  useEffect(() => {
    fetchEmployeeFolders();
  }, []);

  useEffect(() => {
    setSelectedNote(notes.find(({ id }) => id === noteId));
  }, [noteId, notes]);

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  useEffect(() => {
    const regex = new RegExp(employeeSearch.replaceAll('\\', ''), 'ig');
    const filtered: IEmployeeFolder[] = employees.filter((user) =>
      getUserFullName(user).match(regex),
    );
    setFilteredEmployees(filtered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmployeeSearch]);

  const resetFilters = () => {
    setEmployeeSearch('');
  };

  // Following functions will be implemented later
  const onCreateNote = async (createdFor: IUser['id'][]) => {
    $isLoading.on();
    await createNote({ name: 'Unnamed note', createdFor });

    $isLoading.off();
    // TODO - refetch the notes and select the newly created note
    $openCreateNoteModal.off();
  };

  const onDelete = async (noteId: INote['id']) => {
    setNoteToBeDeleted(noteId);
    $openDeleteWarningModal.on();
  };

  const onEdit = async (_note: INote) => {};

  const onUpdate = (_params: Partial<INote>) => {
    autoSaveState.run();
  };

  const onNoteSelect = (memberId?: string, noteId?: string) => {
    history.push(
      routes.NOTEBOOK_OVERVIEW.build(
        { role: ROLES.USER, companyId },
        {
          query: { memberId, noteId },
        },
      ),
    );
  };

  const confirmNoteDelete = async () => {
    $isLoading.on();
    await removeNote(noteToBeDeleted);
    $openDeleteWarningModal.off();
    $isLoading.off();
  };

  return {
    notes,
    onNoteSelect,
    selectedEmployee: memberId,
    selectedNote,
    isFilterVisible,
    onCreateNote,
    onUpdate,
    onDelete,
    onEdit,
    resetFilters,
    isLoading: $isLoading,
    autoSaveState,
    openDeleteWarningModal: $openDeleteWarningModal,
    confirmNoteDelete,
    setNoteToBeDeleted,
    employees,
    filteredEmployees,
    employeeSearch,
    setEmployeeSearch,
    openCreateNoteModal: $openCreateNoteModal,
  };
};

export { useNote };
