import React from 'react';

import { Trans } from '@lingui/macro';

import { NameContainer, OverflowRichText, OverflowText } from '~/components/UserAvatar/design';

function Name({
  name,
  isAnonymous,
  isDeactivated,
  isDeleted,
  anonymityIndex,
  customColor,
  highlight,
}: {
  name: string;
  isAnonymous?: boolean;
  isDeleted?: boolean;
  isDeactivated?: boolean;
  anonymityIndex?: number;
  customColor?: string;
  highlight?: string;
}) {
  return (
    <NameContainer
      $isDeactivated={isDeactivated}
      $isDeleted={isDeleted}
      customColor={customColor}
      className="username"
    >
      {highlight && !isDeleted && !isAnonymous ? (
        <OverflowRichText html={name} highlight={highlight} />
      ) : (
        <OverflowText>
          {!isAnonymous && !isDeleted && name}
          {isAnonymous && !isDeleted && <Trans>Participant {anonymityIndex}</Trans>}
          {isDeleted && <Trans>Deleted user</Trans>}
        </OverflowText>
      )}
    </NameContainer>
  );
}

export { Name };
