import { ROLES } from '@learned/constants';

import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'notebook';

export const NOTEBOOK_OVERVIEW = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(routeName)}`,
);
