import styled from 'styled-components';

import { Icon, ICON_SIZES } from '~/components/Icon';
import Placeholder from '~/components/Placeholder';

import { COLORS } from '~/styles';

export const NotebookMenuContainer = styled.div`
  width: 243px;
  height: 100%;
  background: ${COLORS.BG_PAGE};
  border-right: 1px solid ${COLORS.BORDERS};
`;

export const NotebookHeaderContainer = styled.div`
  display: flex;
  padding: 32px 12px 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const NotebookHeaderTitle = styled.div`
  display: flex;
  width: 109px;
  height: 45px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: ${COLORS.SUBTEXT};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const SearchContainer = styled.div`
  flex: none;
  height: 32px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;

export const SearchPlaceholder = styled.input`
  border: none;
  border-radius: 100px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  padding-left: 34px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${COLORS.BORDERS};
  ::placeholder {
    color: ${COLORS.EDITOR_PLACEHOLDER};
  }
`;

export const SearchIcon = styled(Icon)`
  width: ${ICON_SIZES.MEDIUM};
  height: auto;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.EDITOR_PLACEHOLDER};
`;

export const NotebookFoldersContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px 0 20px 8px;
  box-sizing: border-box;
  overflow-x: hidden;
  gap: 16px;
  height: 100%;

  .folder-list-text {
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: ${COLORS.SUBTEXT};

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const NotebookFoldersList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPlaceholder = styled(Placeholder)`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

export const PlaceholderWrapper = styled.div`
  margin-top: 8px;
`;
