import styled from 'styled-components';

import { COLORS } from '~/styles';

export const NotebookContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const NotesListSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100vh;
  gap: 2px;
  background: ${COLORS.WHITE};
  border-right: 1px solid ${COLORS.BORDERS};
`;

export const NotesListHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 12px 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: 117px;
  width: 100%;
  box-sizing: border-box;
`;

export const NotesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const NotesListTitle = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  flex: 1 0 0;
  color: ${COLORS.LABEL_GRAY};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const NotesListContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  .divider {
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;
