import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

import RickTextView from '../RickTextView';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 100%;
`;

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 7px;
`;

export const Avatars = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
`;

export const MultiContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 5px;
`;

export const NameContainer = styled.span<{
  $isDeactivated?: boolean;
  $isDeleted?: boolean;
  customColor?: string;
}>`
  display: flex;
  flex-direction: row;

  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  ${(props) =>
    props.$isDeactivated &&
    css`
      text-decoration: line-through;
      color: ${COLORS.INACTIVE};
    `}
  ${(props) =>
    props.$isDeleted &&
    css`
      opacity: 0.33;
    `}
    ${({ customColor }) =>
    customColor &&
    css`
      color: ${customColor};
    `} 

  overflow: hidden;
`;

export const OverflowText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OverflowRichText = styled(RickTextView)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${COLORS.TEXT_MAIN};
`;

export const AvatarContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border: solid 1px transparent;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AnonymousCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: ${COLORS.INACTIVE};
`;

export const DeletedCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: ${COLORS.INACTIVE};
  opacity: 0.33;
`;

export const Counter = styled.span<{ $isCounterClickable?: boolean }>`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  text-align: center;
  cursor: ${(props) => (props.$isCounterClickable ? 'pointer' : 'default')};
`;
