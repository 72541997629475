import React from 'react';

import { IUser } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { NotebookPlaceholder } from './NotebookPlaceholder';

import { NotebookFolder } from '../../components/NotebookFolder';
import { IEmployeeFolder } from '../../types';
import { NotebookFoldersList } from '../design';

interface INotebookFolderListProps {
  employeeSearch?: string;
  employees: IEmployeeFolder[];
  selectedEmployeeId: IUser['id'];
  handleFolderSelect: (userId?: string) => void;
}

export const NotebookFolderList = ({
  employeeSearch,
  employees,
  selectedEmployeeId,
  handleFolderSelect,
}: INotebookFolderListProps) => {
  const { i18n } = useLingui();

  return (
    <NotebookFoldersList>
      <div className="folder-list-text">
        <Trans>Notes about employees</Trans>
      </div>
      {employees.map(({ id }) => (
        <NotebookFolder
          key={id}
          userId={id}
          isSelected={selectedEmployeeId === id}
          onClick={() => handleFolderSelect(id)}
          employeeSearch={employeeSearch}
        />
      ))}

      {isEmpty(employees) && !employeeSearch && (
        <NotebookPlaceholder
          title={i18n._(t`No notes`)}
          subTitle={i18n._(t`No notes about other employees written yet.`)}
        />
      )}
    </NotebookFoldersList>
  );
};
