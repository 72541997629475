import { useEffect, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { orderBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { getUsers } from '~/selectors/baseGetters';
import { getNotes, removeNote, updateNote } from '~/services/notes';

import { confirm } from '../ConfirmationModal/confirm';

import type { INoteEditorForm } from './types';
import type { INoteOld, IUser } from '@learned/types';

const useNotebookModal = () => {
  const { i18n } = useLingui();
  const [notes, setNotes] = useState<INoteOld[]>([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [editNoteId, setEditNoteId] = useState('');
  const [scrollItemId, setScrollItemId] = useState('');
  const isFilterVisible = useBoolState(false);
  const isLoading = useBoolState(true);
  const formMethods = useForm<INoteEditorForm>();
  const allUsers = useSelector(getUsers);
  const [searchParticipants, setSearchParticipants] = useState('');

  const participants = useMemo(() => {
    const searchRegex = new RegExp(searchParticipants, 'i');
    const getFullName = ({ firstName, lastName }: { firstName: string; lastName: string }) =>
      `${firstName || ''} ${lastName || ''}`;

    const searchedUsers = Object.values(allUsers as Record<IUser['id'], IUser>).filter(
      (item) => searchRegex.test(getFullName(item)) || searchRegex.test(item.email),
    );

    return orderBy(searchedUsers, (item) => getFullName(item)).map((item) => item.id);
  }, [allUsers, searchParticipants]);

  const fetchNotes = async () => {
    const data = await getNotes({ search: debouncedSearch, employees: [...selectedEmployees] });
    setNotes(Object.values(data));
    isLoading.off();
  };

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, selectedEmployees]);

  const resetFilters = () => {
    setSelectedEmployees([]);
    setSearch('');
  };

  const isFiltered = useMemo(() => {
    return !isEmpty(selectedEmployees) || !isEmpty(search);
  }, [search, selectedEmployees]);

  const onCreateNote = async ({ createdFor, value }: INoteEditorForm) => {
    if (editNoteId) {
      const data = await updateNote(editNoteId, { createdFor, value });
      if (data) {
        setNotes((prevNotes) =>
          prevNotes.map((note) => {
            if (note.id === editNoteId) {
              return {
                ...note,
                createdFor,
                value,
              };
            } else {
              return note;
            }
          }),
        );
        setEditNoteId('');
      }
    }
  };

  const onDelete = async (noteId: INoteOld['id']) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete?`),
      description: i18n._(t`Are you sure you want to delete this note?`),
    });
    if (isConfirmed) {
      const data = await removeNote(noteId);
      if (data) {
        setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
      }
      setEditNoteId('');
    }
  };

  const onEdit = async (note: INoteOld) => {
    formMethods.setValue('value', note.value);
    formMethods.setValue('createdFor', note.createdFor);
    setEditNoteId(note.id);
  };

  return {
    notes,
    formMethods,
    search,
    setSearch,
    selectedEmployees,
    setSelectedEmployees,
    isFilterVisible,
    onCreateNote,
    onDelete,
    onEdit,
    participants,
    resetFilters,
    isFiltered,
    isLoading,
    scrollItemId,
    setScrollItemId,
    setSearchParticipants,
    totalUsersCount: Object.values(allUsers).length,
  };
};

export { useNotebookModal };
