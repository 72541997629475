import React from 'react';

import { INote } from '@learned/types';
import { Trans } from '@lingui/macro';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import Divider from '~/components/UI/Divider';

import { Container, Content, Footer, Title, TitleWrapper } from './design';

import { getFormattedDate } from '../../utils';
import { UserAvatarsGroup } from '../UserAvatarGroup';

interface INoteSummaryProps {
  note: INote;
  isSelected?: boolean;
  onDelete?: (noteId: string) => void;
  onClick: () => void;
}

export const NoteSummary = ({ note, isSelected, onDelete, onClick }: INoteSummaryProps) => {
  return (
    <>
      <Container isSelected={!!isSelected} onClick={onClick}>
        <TitleWrapper className="title">
          <Title>
            <Trans>{note.name}</Trans>
          </Title>
          <ContextMenu className="menu" menuHeight={100}>
            <ContextOption
              action={() => onDelete?.(note.id)}
              key="delete"
              icon={ICONS.DELETE_BIN}
              isWarning
            >
              <Trans>Delete</Trans>
            </ContextOption>
          </ContextMenu>
        </TitleWrapper>
        {<Content html={note.value} />}
        <Footer>
          {
            <span className="note-date">
              {getFormattedDate(new Date(note?.meta?.createdDate ?? ''))}
            </span>
          }
          {note.createdFor?.length && <UserAvatarsGroup createdFor={note.createdFor} />}
        </Footer>
      </Container>
      <Divider className="divider" />
    </>
  );
};
